.pep-accordian-item {
  .heading-wrapper {
    background: #d4dadf;
    .toggle-heading {
      line-height: 1.5;
      border: transparent;
      cursor: pointer;
    }
  }

  .content {
    border: 1px solid #ddd;
  }
}
