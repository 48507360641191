.popover-cta {
  max-width: 400px;
  word-break: break-all;
  background-color: white;
  padding: 16px;
  border: 1px solid rgba(33, 33, 33, 0.25);
  border-radius: 4px;
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(0, -20px, 0);
  [data-enter] & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
