@import "styles/variables";

.subscriber-form {
  input.form-control {
    border-radius: 0;
    &:focus {
      border-width: 1px;
    }
  }
  .subscribe-btn {
    max-width: 120px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
  }
}
