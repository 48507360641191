$width: 90px;
$fontSize: 14px;
$activeColor: #666a37;
$inactiveColor: #f8f8f8;

.switch-button {
  background: $inactiveColor;
  border-radius: 30px;
  overflow: hidden;
  width: calc(#{$width}* 2);
  text-align: center;
  font-size: $fontSize;
  letter-spacing: 1px;
  //color: #000;
  position: relative;
  padding-right: $width;
  position: relative;

  &::before {
    content: "Original";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: $width;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }
  .switch-button-checkbox + .switch-button-label {
    color: white;
  }
  .switch-button-checkbox:checked + .switch-button-label {
    color: black;
  }
  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX($width);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 8px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: $activeColor;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}

.sb-white {
  &::before {
    color: white !important;
  }
}
