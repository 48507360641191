$primary: #f75151;
$primary2: #043d5d;
$secondary: #819eae;
$borderColor: #707071;

// Toastify
$rt-color-default: #fff !default;
$rt-color-info: #3498db !default;
$rt-color-success: #07bc0c !default;
$rt-color-warning: #f1c40f !default;
$rt-color-error: #e74c3c !default;
