@import "~styles/variables";
.checkbox {
  label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 1px;
    margin-left: -25px;
    border-radius: 2px;
    border: 2px solid $primary;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  .border-default::before {
    border: 1px solid $borderColor;
  }

  label::after {
    content: "";
    position: absolute;
    width: 6px;
    height: 12px;
    margin-left: -25px;
    top: 4px;
    left: 5px;
    margin-top: -3px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background: none !important;
    transform: rotate(45deg);
    display: none;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + label::before,
  input:checked + label::after {
    background: $primary;
    display: block;
  }

  input:checked + .border-default::before {
    border: 1px solid transparent;
  }
}
