//@import "./variables";

:root {
  --primary: #f75151;
  --primary2: #043d5d;
  --secondary: #819eae;
}

//tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Toastify
@import "~react-toastify/scss/main";

$project-font-path: "./fonts/";

@layer base {
  @font-face {
    font-family: "Century Gothic";
    src: url("#{$project-font-path}GOTHIC.TTF");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Century Gothic";
    src: url("#{$project-font-path}GOTHICI.TTF");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Century Gothic";
    src: url("#{$project-font-path}GOTHICB.TTF");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Century Gothic";
    src: url("#{$project-font-path}GOTHICBI.TTF");
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "NanumGothic";
    src: url("#{$project-font-path}NanumGothic-Regular.ttf");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "NanumGothic";
    src: url("#{$project-font-path}NanumGothic-Bold.ttf");
    font-weight: 600;
    font-style: normal;
  }

  a {
    @apply text-primary2 hover:text-primary;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary border border-primary  text-white uppercase py-2 px-4;
  }

  .btn-primary-outline {
    @apply border border-primary  text-primary uppercase py-2 px-4;
  }

  .btn-secondary {
    @apply bg-secondary border border-secondary  text-white uppercase py-2 px-4;
  }

  .btn-secondary-outline {
    @apply border border-secondary  text-secondary uppercase py-2 px-4;
  }
}

.slick-prev,
.slick-next {
  background: #ffffff !important;
}
.slick-prev:before,
.slick-next:before {
  color: #043d5d !important;
}
.slick-next {
  right: -19px !important;
}
.slick-prev {
  left: -19px !important;
}
