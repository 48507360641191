.pep-menu {
  li {
    a.active {
      position: relative;
      &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 3px;
        background-color: red;
        content: "";
      }
    }
  }
}
